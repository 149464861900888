@import "@style/theme.scss";
@import "@style/media.scss";


.header {
    min-height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    
    @include modal-padding;
}

@mixin header-panel {
    flex-shrink: 1;
    width: 50%;
    display: flex;
    align-items: center;
}

.header-panel {

    &--left {
        @include header-panel;
        justify-content: flex-start;
    }

    &--right {
        @include header-panel;
        justify-content: flex-end;
    }
}

.back-icon-container {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    color: $fg-primary;
}

.back-icon-text {
    @include font-bold;
    line-height: 22px;
    padding-left: 4px;
}

.close-icon {
    cursor: pointer;
    height: 22px;
    width: 22px;
    color: #BDBDBE; //$fg-primary;
    user-select: none;
    z-index: $z-index-loader + 1;

    :global(#modal-root-container.portal-open) & {
        display: none;
    }

    & > svg {
        height: 100%;
        width: 100%;
    }
}

.lang-dropdown {
    padding: 3px;
    margin-left: 16px;
    border-color: #BDBDBE;
    border-radius: 4px;
}