@import "@style/theme.scss";

.header {
    display: flex;
    position: relative;
}

.cbb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    z-index: $z-index-loader + 1;

    img {
        height: 30px;
    }
}

.kbb {
    width: 216px;
    position: absolute;
    padding-left: 30px;
    margin-top: -6px;
    z-index: $z-index-loader + 1;
}

.lang-dropdown {
    padding: 3px;
    margin: 4px 28px;
    border-color: #BDBDBE;
    border-radius: 4px;
    position: absolute;
    right: 0;
}